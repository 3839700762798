<template>
  <div id="cmarketing">
    <div class="crumbs">
      <router-link to="/control/companyc">首页</router-link>
      <label>></label>
      <a>推广宣传</a>
    </div>
    <!-- <div class="search">
      <div>
        <label>标地名称</label>
        <el-input v-model="search.name"></el-input>
      </div>
      <div>
        <button @click="getData()">搜索</button>
        <router-link to="/control/cmarketing/add">新增标地推广</router-link>
      </div>
    </div> -->
    <div class="group">
      <div class="search-input-group">
        <el-input
          type="text"
          placeholder="根据标地名称搜索"
          v-model="search.name"
        />
        <p @click="getData()">搜索</p>
      </div>
      <div
        class="search-filter-group"
        @click="$router.push('/control/cmarketing/add')"
      >
        新增标地推广
      </div>
    </div>
    <el-table :data="tableData" v-loading="loading" highlight-current-row>
      <el-table-column label="产品" prop="name" width="210"></el-table-column>
      <el-table-column prop="status" label="推广状态">
        <template slot-scope="scope">
          {{ scope.row.status | status }}
        </template>
      </el-table-column>
      <el-table-column label="推广周期">
        <template slot-scope="scope">
          {{
            `${$dayjs(scope.row.beginDate).format("YYYY-MM-DD")} - ${$dayjs(
              scope.row.endDate
            ).format("YYYY-MM-DD")}`
          }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="140">
        <template slot-scope="scope">
          <template v-if="scope.row.status === 3 || scope.row.status === 4">
            <i @click="edit(scope.row.id)">编辑</i>
            <i @click="del(scope.row.id)">删除</i>
          </template>
          <template v-else>
            <i class="not">编辑</i>
            <i class="not">删除</i>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <div class="page">
      <el-pagination
        v-if="page.pages > 1"
        background
        layout="prev, pager, next"
        :page-size="page.pageSize"
        :total="page.total"
        @current-change="getData"
        :current-page.sync="page.pageNum"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "cmarketing-index",
  data() {
    return {
      loading: false,
      search: {
        name: "",
      },
      tableData: [],
      page: {
        pageNum: 0,
        pageSize: 6,
        total: 0,
        pages: 0,
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData(pageNum = 1) {
      this.loading = true;
      this.$axios
        .get("/subject/subjectMarketing", {
          params: {
            subjectName: this.search.name,
            pageNum,
          },
        })
        .then((res) => {
          // console.log(res.data);
          if (res.state === 200) {
            const { list, pageNum, total, pages } = res.data;
            this.tableData = list;
            this.page.pageNum = pageNum;
            this.page.total = total;
            this.page.pages = pages;
            this.loading = false;
            this.search.name = "";
          } else {
            this.$message.error(res.message);
          }
        });
    },
    edit(id) {
      this.$router.push({
        path: "/control/cmarketing/edit",
        query: {
          id,
        },
      });
    },
    del(v) {
      this.$confirm("确认删除?", "提示", {
        type: "warning",
      })
        .then(() => {
          // console.log(v);
          this.$axios.post(`/subject/deleteSubjectPop/${v}`).then((res) => {
            if (res.state === 200) {
              this.$message.success(res.message);
              this.getData();
            } else {
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {});
    },
  },
  filters: {
    status(v) {
      const exist = v !== null || v !== undefined || v !== "";
      const result = {
        1: "提交审核",
        2: "推广中",
        3: "审批失败",
        4: "推广结束",
      };
      return exist ? result[v] : "";
    },
  },
};
</script>

<style lang="scss" scoped>
#cmarketing {
  display: flex;
  flex-direction: column;
  margin: 0 0 114px;
  .group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;

    .search-input-group {
      display: flex;
      .el-input {
        width: 300px;
        height: 34px;
        line-height: 34px;
        background: #ffffff;
        outline: none;
      }
      p {
        width: 50px;
        height: 40px;
        background: #3883ff;
        border: 1px solid #3883ff;
        text-align: center;
        color: #ffffff;
        line-height: 34px;
        &:active {
          background: #296ad6;
        }
      }
      p:hover {
        cursor: pointer;
      }
    }
    .search-filter-group {
      height: 40px;
      background: #3883ff;
      border: 1px solid #3883ff;
      text-align: center;
      color: #ffffff;
      line-height: 34px;
      padding: 0 23px;
    }
    .search-filter-group:hover {
      cursor: pointer;
    }
  }
}
</style>
